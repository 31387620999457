import React from "react";
import Text from "../Text/Text";

export default function Certification({ src, dark, title, body }) {
  return (
    <div
      style={{
        flex: 1,
        minWidth: 500,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: dark ? "#E8E8E8" : "#FFFFFF",
        borderTop: dark ? "none" : "1px solid #E8E8E8",
      }}
    >
      <img
        src={src}
        style={{ height: "80%", padding: 24, maxHeight: 400 }}
        alt="logo"
      />
      <div
        style={{
          width: "-webkit-fill-available",
          padding: 24,
          height: "100%",
          paddingTop: "20%",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <Text color="#F0A202" fontWeight="bold" fontSize="24px">
          {title}
        </Text>
        <Text fontFamily="Roboto" fontWeight="light" fontSize="20px">
          {body}
        </Text>
      </div>
    </div>
  );
}
