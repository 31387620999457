import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../components/Banner/Banner";
import banner from "../../images/ClientNetwork/Banners/banner.jpeg";
import Content from "../../components/Content/Content";
import Heading from "../../components/Text/Heading";
import Footer from "../../components/Footer/Footer";
import ClientNetworkCarousel from "../../components/Carousels/ClientNetworkCarousel";
import Text from "../../components/Text/Text";
import LogoCascade from "../../components/LogoCascade/LogoCascade";
import {
  governmentLogos,
  bankingGovLogos,
  bankingPrivateLogos,
  bankingInternationalLogos,
  financialInstitutionLogos,
  eCommerceLogos,
  transportationLogos,
  telecomEntertainmentLogos,
  insuranceLogos,
  realEstateLogos,
  educationLogos,
} from "../../components/Svg/ClientLogos/ClientLogos";

const useStyles = makeStyles({
  carouselWrapper: {
    height: 750,
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  carouselBanner: {
    backgroundColor: "black",
    height: 750,
    position: "absolute",
    left: "10vw",
    minWidth: "40vw",
  },
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
  circle: {
    backgroundColor: "#FFFFFF",
    height: 400,
    width: 400,
    borderRadius: 400,
  },
  photoPlaceholder: {
    width: 400,
    height: 250,
    backgroundColor: "grey",
    marginBottom: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tiles: {
    width: "100%",
    height: 500,
    display: "flex",
    justifyContent: "row",
  },
  statistic: {
    flexGrow: 1,
  },
});

const transitionTime = 1500;
const interval = 6000;

export default function ClientNetwork({ scrollToRef }) {
  const [quote, setQuote] = React.useState(0);
  const classes = useStyles();

  return (
    <Content>
      <div ref={scrollToRef}>
        <Banner background={banner} height={500}>
          <Heading color="#FFFFFF">Our Valued Customers</Heading>
        </Banner>
      </div>
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: 100,
              width: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div className={classes.textSectionWrapper}>
              <div className={classes.textSectionBorder} />
              <div className={classes.textSection}>
                <Text>
                  While consumer patterns have evolved over the past 20 years,
                  we remain steadfast in delivering creative solutions and in
                  developing efficacious means to grapple with the shifting
                  trends and emerging technology in the digital payment
                  landscape.
                </Text>
                <Text>
                  We strive to strike a holistic equilibrium that affords our
                  clients greater latitude to draw strategic insights and to
                  meet the dynamic needs unique to each industry portfolio.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.tiles} style={{ height: 700 }}>
        <div style={{ width: "100vw" }}>
          <ClientNetworkCarousel
            height={700}
            transitionTime={transitionTime}
            interval={interval}
          />
        </div>
      </section>
      <section>
        <LogoCascade
          header="GOVERNMENT INSTITUTIONS"
          noBorder
          logos={governmentLogos}
        />
        <LogoCascade header="BANKING - GOVERNMENT" logos={bankingGovLogos} />
        <LogoCascade header="BANKING - PRIVATE" logos={bankingPrivateLogos} />
        <LogoCascade
          header="BANKING - INTERNATIONAL"
          logos={bankingInternationalLogos}
        />
        <LogoCascade
          header="FINANCIAL INSTITUTIONS"
          logos={financialInstitutionLogos}
        />
        <LogoCascade
          header="E-COMMERCE, E-WALLET & E-MONEY"
          logos={eCommerceLogos}
        />
        <LogoCascade header="TRANSPORT" logos={transportationLogos} />
        <LogoCascade
          header="TELECOMMUNICATION & ENTERTAINMENT"
          logos={telecomEntertainmentLogos}
        />
        <LogoCascade header="INSURANCE" logos={insuranceLogos} />
        <LogoCascade header="REAL ESTATE" logos={realEstateLogos} />
        <LogoCascade header="EDUCATION" logos={educationLogos} />
      </section>
      <Footer language="en" />
    </Content>
  );
}
