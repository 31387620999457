import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  carouselWrapper: {
    height: 750,
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  carouselBanner: {
    backgroundColor: "black",
    height: 750,
    position: "absolute",
    left: "10vw",
    minWidth: "40vw",
  },
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: 300,
    lineHeight: "1.5em",
  },
  circle: {
    backgroundColor: "#FFFFFF",
    height: 400,
    width: 400,
    borderRadius: 400,
  },
  photoPlaceholder: {
    width: 400,
    height: 250,
    backgroundColor: "grey",
    marginBottom: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tiles: {
    width: "100%",
    height: 500,
    display: "flex",
    justifyContent: "row",
  },
  statistic: {
    flexGrow: 1,
  },
});

export default function Text({
  children,
  color,
  fontWeight,
  textTransform,
  fontSize,
  fontFamily,
  style,
}) {
  const classes = useStyles();

  return (
    <p
      className={classes.text}
      style={{
        color,
        fontWeight,
        textTransform,
        fontFamily,
        fontSize,
        ...style,
      }}
    >
      {children}
    </p>
  );
}
