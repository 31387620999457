import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../components/Banner/Banner";
import efficiencybanner from "../../images/Home/Banners/efficiencybanner.jpeg";
import EfficiencySlogan from "../../components/Svg/EfficiencySlogan";
import Content from "../../components/Content/Content";
import HomeBannerCarousel from "../../components/Carousels/HomeBannerCarousel";
import Button from "../../components/Button/Button";
import Heading from "../../components/Text/Heading";
import Tile from "../../components/Tile/Tile";
import agents from "../../images/Home/Tiles/agents.png";
import banks from "../../images/Home/Tiles/banks.png";
import partners from "../../images/Home/Tiles/partners.png";
import BankStat from "../../components/Svg/BanksStat";
import PartnersStat from "../../components/Svg/PartnersStat";
import AgentsStat from "../../components/Svg/AgentsStat";
import Footer from "../../components/Footer/Footer";
import HomeQuoteCarousel from "../../components/Carousels/HomeQuoteCarousel";
import Text from "../../components/Text/Text";
import { Link, useLocation, matchPath } from "react-router-dom";

const useStyles = makeStyles({
  carouselWrapper: {
    height: "100vh",
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  carouselBanner: {
    backgroundColor: "black",
    height: "100vh",
    position: "absolute",
    left: "10vw",
    minWidth: "40vw",
  },
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
  circle: {
    backgroundColor: "#FFFFFF",
    height: 400,
    width: 400,
    borderRadius: 400,
  },
  photoPlaceholder: {
    width: 400,
    height: 250,
    backgroundColor: "grey",
    marginBottom: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tiles: {
    width: "100%",
    height: 500,
    display: "flex",
    justifyContent: "row",
  },
  statistic: {
    flexGrow: 1,
  },
});

export default function Home({ scrollToRef }) {
  const classes = useStyles();

  const location = useLocation();

  const language = matchPath(location.pathname, {
    path: "/id",
    exact: false,
    strict: false,
  })
    ? "id"
    : "en";

  return (
    <>
      <div ref={scrollToRef} />
      <Content>
        <HomeBannerCarousel height={"95vh"} />
        <section id="mainSection" className={classes.mainSection}>
          <div className={classes.row}>
            <div
              style={{
                margin: 100,
                width: "70%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <div className={classes.textSectionWrapper}>
                <div className={classes.textSectionBorder} />
                <div className={classes.textSection}>
                  <Heading>Our SYB</Heading>
                  <Text>
                    PT Sarana Yukti Bandhana (
                    <span style={{ color: "#F0A202", fontWeight: "bold" }}>
                      SYB
                    </span>
                    ) is a payment systems provider and operator. For more than
                    20 years, SYB has pioneered a digital payment ecosystem that
                    ensures transactions are processed in a streamlined and
                    secure manner.
                  </Text>
                  <Text>
                    SYB offers a diverse array of digital payment channels,
                    serving a myriad of sectors, ranging from public utilities,
                    banking, finance, insurance, education, telecommunication,
                    entertainment, online gaming, e-commerce, to e-wallet and
                    e-money.
                  </Text>
                  <Text>
                    We strive to be on the vanguard of the digital landscape and
                    provide our customers with sound and innovative financial
                    solutions, tailored to advance financial services across
                    Asia.
                  </Text>
                </div>
              </div>
              <Link
                to={`/${language}/ourjourney`}
                style={{ textDecoration: "none" }}
              >
                <Button style={{ marginRight: 40 }}>Learn more</Button>
              </Link>
            </div>
          </div>
        </section>
        <Link to={`/${language}/clients`}>
          <section className={classes.tiles}>
            <Tile background={partners}>
              <PartnersStat />
            </Tile>
            <Tile background={banks}>
              <BankStat />
            </Tile>
            <Tile background={agents}>
              <AgentsStat />
            </Tile>
          </section>
        </Link>
        <section className={classes.banner}>
          <Banner
            background={efficiencybanner}
            height={500}
            style={{
              alignItems: "flex-start",
              backgroundPosition: "bottom",
            }}
          >
            <div style={{ paddingLeft: 40 }}>
              <EfficiencySlogan />
            </div>
          </Banner>
        </section>
        <section className={classes.tiles} style={{ height: 700 }}>
          <div style={{ width: "100vw" }}>
            <HomeQuoteCarousel
              transitionTime={1500}
              interval={10000}
              height={700}
            />
          </div>
        </section>
        <Footer language="en" />
      </Content>
    </>
  );
}
