import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Text from "../Text/Text";

const fontSize = 16;
const linesToShow = 7;
const lineHeight = 1.4;

const useStyles = makeStyles({
  root: {
    background: "#fff",
    height: "15vw",
    height: 450,
    margin: "1rem",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  expand: {
    height: 550,
  },
  imageBackground: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    transform: "scale(1.0)",
    transition: "opacity 0.4s, transform 0.4s",
  },
  hover: {
    transform: "scale(1.1)",
    opacity: 1,
  },
  text: {
    maxWidth: "400px",
    margin: "0 auto",
    lineHeight: 1.4,
    fontSize: fontSize,
  },
  collapsed: {
    display: "block" /* Fallback for non-webkit */,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: fontSize * lineHeight * linesToShow /* Fallback for non-webkit */,
    WebkitLineClamp: linesToShow,
  },
});

function Service({ image, title, collapsable, content }) {
  const [hover, setHover] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const classes = useStyles();

  function handleClick() {
    if (collapsable) {
      setExpand(!expand);
    }
  }

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={clsx(classes.root, { [classes.expand]: expand })}
    >
      <div
        style={{
          width: "100%",
          height: 200,
          maxHeight: 200,
          minHeight: 200,
          borderRadius: 16,
          boxShadow:
            "0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23)",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={image}
          className={clsx(classes.imageBackground, { [classes.hover]: hover })}
          style={{
            opacity: 1.0,
          }}
        />
      </div>
      <div
        style={{
          padding: 16,
          cursor: collapsable ? "pointer" : "auto",
          textAlign: "center",
        }}
        onClick={handleClick}
      >
        <Text
          color="#F0A202"
          fontSize={18}
          fontWeight="bold"
          style={{ height: "4.5em" }}
        >
          {title}
        </Text>
        <p
          className={clsx(classes.text, {
            [classes.collapsed]: collapsable && !expand,
          })}
        >
          {content}
        </p>
      </div>
    </div>
  );
}

export default Service;
