import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import background1 from "../../images/ClientNetwork/Carousel/1.jpeg";
import background2 from "../../images/ClientNetwork/Carousel/2.jpeg";
import background3 from "../../images/ClientNetwork/Carousel/3.jpeg";
import { makeStyles } from "@material-ui/core/styles";
import Heading from "../Text/Heading";
import Text from "../Text/Text";

const useStyles = makeStyles({
  carouselWrapper: {
    height: 750,
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  carouselBanner: {
    backgroundColor: "rgba(0,0,0,0.6)",
    height: 750,
    position: "absolute",
    left: "10vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingInline: "50px",
  },
  carouselSlide1: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background1})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide2: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselSlide3: {
    backgroundColor: "#CCCCCC",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background3})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  mainSection: {
    backgroundColor: "#00004D",
    margin: 0,
    color: "#FFFFFF",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textLarge: {
    fontFamily: "Roboto",
    fontSize: 80,
    padding: 0,
    margin: 0,
    fontWeight: "bold",
    color: "white",
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 28,
    padding: 0,
    margin: 0,
    color: "white",
    fontWeight: 100,
    minWidth: "30vw",
    maxWidth: "500px",
  },
});

const quotes = [
  {
    heading: "500+ Mitra Perusahaan",
    content:
      "Kami berusaha untuk menciptakan pengalaman yang personal di setiap tahap siklus penanganan pelanggan. Dengan pemahaman kami tentang pola pembayaran pelanggan yang unik untuk setiap portofolio industri, kami menawarkan wawasan perilaku yang bernuansa dan solusi khusus untuk mendefinisikan kembali proses pembelian dan penagihan kontemporer.",
  },
  {
    heading: "10K+ Agen",
    content:
      "Kami menyokong kesamaan dalam berkesempatan dan berkomitmen untuk mendorong perkembangan inklusivitas dalam ekonomi digital saat ini. Kami berusaha untuk memfasilitasi pelanggan yang tidak memiliki rekening bank dalam perjalanan mereka untuk mengakses metode pembayaran dan tagihan modern serta dalam melatih pelaku usaha perorangan dan pengecer untuk menjadi peserta aktif dan terinformasi dalam ekosistem pembayaran digital.",
  },
  {
    heading: "40+ Bank",
    content:
      "Ekosistem pembayaran digital mencakup beragam bank, baik itu pemerintah atau komersial, internasional atau lokal, atau bank besar hingga bank khusus. Kami melayani seluruh spektrum bank dan menawarkan solusi digital untuk memproses pembayaran tagihan dan isi ulang.",
  },
];

function HomeQuoteCarousel({ height, transitionTime = 1500, interval = 6000 }) {
  const classes = useStyles();

  const indicatorStyles = {
    display: "inline-block",
    marginRight: "10px",
    color: "#fff",
    cursor: "pointer",
    height: 10,
    width: 10,
    zIndex: 10,
    marginRight: 8,
    borderRadius: "50%",
    "-webkit-transition": "background-color 0.6s ease",
    transition: "background-color 0.6s ease",
    border: "2px solid #141413",
    transform: "translateX(200px) translateY(-50px)",
  };

  const imageClasses = [
    classes.carouselSlide1,
    classes.carouselSlide2,
    classes.carouselSlide3,
  ];

  return (
    <div className={classes.carouselWrapper} style={{ height, width: "100%" }}>
      <Carousel
        autoPlay
        swipeable={false}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        showIndicators={true}
        transitionTime={transitionTime}
        interval={interval}
        renderIndicator={(onClickHandler, isSelected, index, label) => (
          <li
            style={{
              ...indicatorStyles,
              background: isSelected ? "#141413" : "transparent",
              outline: isSelected ? "1px solid #141413" : "none",
            }}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            aria-label={`${label} ${index + 1}`}
          />
        )}
      >
        {quotes.map((quote, index) => (
          <div style={{ width: "100vw", display: "flex", height }}>
            <div style={{ width: "50%" }}>
              <section className={imageClasses[index]} style={{ height }} />
            </div>
            <div
              style={{
                width: "50%",
                backgroundColor: "#FFFFFF",
                margin: 0,
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <div style={{ width: 500, color: "#1B297C" }}>
                <div>
                  {quote.heading && (
                    <Heading variant="h2" color="#00004D">
                      {quote.heading}
                    </Heading>
                  )}
                  <Text>{quote.content}</Text>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default HomeQuoteCarousel;
