import React from "react";
import NavBar from "../components/NavBar/NavBar";
import NavBarLink from "../components/NavBarLink/NavBarLink";
import NavBarDropdown from "../components/NavBarDropdown/NavBarDropdown";
import NavLogo from "../components/Svg/NavLogo";
import LanguageToggle from "../components/LanguageToggle/LanguageToggle";
import LanguageToggleId from "../components/LanguageToggle/LanguageToggle_id";
import { matchPath } from "react-router";
import { useLocation } from "react-router";

export default function NavigationBar() {
  const location = useLocation();

  const language = matchPath(location.pathname, {
    path: "/id",
    exact: false,
    strict: false,
  })
    ? "id"
    : "en";

  const Toggle = language == "en" ? LanguageToggle : LanguageToggleId;
  return (
    <NavBar>
      <NavBarLink logo to={`/${language}`}>
        <NavLogo style={{ height: "90%" }} />
      </NavBarLink>
      <NavBarDropdown title={language == "en" ? "About Us" : "TENTANG KAMI"}>
        <NavBarLink to={`/${language}/visionvalues`}>
          {language == "en" ? "Vision and Values" : "Visi dan Nilai-Nilai"}
        </NavBarLink>
        <NavBarLink to={`/${language}/ourjourney`}>
          {language == "en" ? "Our Journey" : "Perjalanan Kami"}
        </NavBarLink>
      </NavBarDropdown>
      <NavBarLink to={`/${language}/capabilities`}>
        {language == "en" ? "Capabilities" : "KEMAMPUAN"}
      </NavBarLink>
      <NavBarLink to={`/${language}/clients`}>
        {language == "en" ? "Our Clients" : "KLIEN KAMI"}
      </NavBarLink>
      <NavBarLink to={`/${language}/news`}>
        {language == "en" ? "News" : "Berita"}
      </NavBarLink>
      <NavBarLink to={`/${language}/contact`}>
        {language == "en" ? "Contact" : "Kontak"}
      </NavBarLink>
      <NavBarLink logo to={`/${language == "en" ? "id" : "en"}/`}>
        <Toggle scale={0.7} />
      </NavBarLink>
    </NavBar>
  );
}
