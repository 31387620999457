import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    maxWidth: 800,
    minWidth: "max(40vw, 500px)",
    background: "#fff",
    borderRadius: 10,
    height: "calc(45vw + 4rem)",
    maxHeight: "calc(900px + 4rem)",
    minHeight: 500,
    margin: "1rem",
    boxShadow: "0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    },
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    flex: 1,
    cursor: "pointer",
    position: "relative",
  },
  imageBackground: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    transform: "scale(1.0)",
    transition: "opacity 0.4s, transform 0.4s",
  },
  hover: {
    transform: "scale(1.1)",
    opacity: 1,
  },
});

function Poster({ image, children }) {
  const [hover, setHover] = React.useState(false);
  const classes = useStyles();

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={classes.root}
    >
      <div
        style={{
          position: "absolute",
          width: "-webkit-fill-available",
          height: "fit-content",
          zIndex: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.4)",
          margin: "8%",
        }}
      >
        {children}
      </div>
      <img
        src={image}
        className={clsx(classes.imageBackground, { [classes.hover]: hover })}
        style={{
          opacity: 1.0,
        }}
      />
    </div>
  );
}

export default Poster;
