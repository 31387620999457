import React from "react";
import { Slide, Fade } from "react-awesome-reveal";

export default function Cascade({ items, margin = 40, padding = 40 }) {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          width: "-webkit-fill-available",
          margin,
          padding,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {items?.map((x) => (
            <Slide
              direction="down"
              triggerOnce={true}
              duration={1500}
              fraction={0.1}
            >
              <Fade duration={2000} triggerOnce={true}>
                {x}
              </Fade>
            </Slide>
          ))}
        </div>
      </div>
    </div>
  );
}
