import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  matchPath,
} from "react-router-dom";
import Home from "./pages/en/Home";
import News from "./pages/en/News";
import Contact from "./pages/en/Contact";
import ClientNetwork from "./pages/en/ClientNetwork";
import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton";
import VisionValues from "./pages/en/VisionValues";
import OurJourney from "./pages/en/OurJourney";
import Capabilities from "./pages/en/Capabilities";
import SampleBlogPost from "./pages/en/blog/SampleBlogPost";
import HomeInd from "./pages/id/Home_id";
import NewsInd from "./pages/id/News_id";
import ContactInd from "./pages/id/Contact_id";
import ClientNetworkInd from "./pages/id/ClientNetwork_id";
import VisionValuesInd from "./pages/id/VisionValues_id";
import OurJourneyInd from "./pages/id/OurJourney_id";
import CapabilitiesInd from "./pages/id/Capabilities_id";
import SampleBlogPostInd from "./pages/id/blog/SampleBlogPost_id";
import Video from "./videos/loading.mp4";
import { VideoBackground } from "./components/VideoBackground/VideoBackground";
import NavigationBar from "./containers/NavigationBar";

function Loading() {
  return <VideoBackground autoPlay loop muted src={Video} type="video/mp4" />;
}

function MultiLangPage({ pages }) {
  const location = useLocation();

  let language = "en";

  const isHomePageId = matchPath(location.pathname, {
    path: "/id",
    exact: false,
    strict: false,
  });

  if (isHomePageId) language = "id";

  return pages[language];
}

export default function App() {
  const [showGoTop, setShowGoTop] = React.useState(false);
  const [displayLoading, setDisplayLoading] = React.useState(false);

  const refScrollUp = React.useRef();

  const handleVisibleButton = () => {
    setShowGoTop(window.scrollY > 50);
  };

  React.useEffect(() => {
    document.addEventListener("scroll", handleVisibleButton, true);
    return document.removeEventListener("scroll", handleVisibleButton);
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setDisplayLoading(false);
    }, 2000);
  }, []);

  const handleScrollUp = () => {
    if (refScrollUp.current)
      refScrollUp.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  };

  return (
    <Router>
      {displayLoading && <Loading />}
      <NavigationBar />
      <ScrollToTopButton showGoTop={showGoTop} scrollUp={handleScrollUp} />
      <Switch>
        <Route path="/:language/visionvalues">
          <MultiLangPage
            pages={{
              en: <VisionValues scrollToRef={refScrollUp} />,
              id: <VisionValuesInd scrollToRef={refScrollUp} />,
            }}
          />
        </Route>
        <Route path="/:language/ourjourney">
          <MultiLangPage
            pages={{
              en: <OurJourney scrollToRef={refScrollUp} />,
              id: <OurJourneyInd scrollToRef={refScrollUp} />,
            }}
          />
        </Route>
        <Route path="/:language/capabilities">
          <MultiLangPage
            pages={{
              en: <Capabilities scrollToRef={refScrollUp} />,
              id: <CapabilitiesInd scrollToRef={refScrollUp} />,
            }}
          />
        </Route>
        <Route path="/:language/clients">
          <MultiLangPage
            pages={{
              en: <ClientNetwork scrollToRef={refScrollUp} />,
              id: <ClientNetworkInd scrollToRef={refScrollUp} />,
            }}
          />
        </Route>
        <Route path="/:language/news">
          <MultiLangPage
            pages={{
              en: <News scrollToRef={refScrollUp} />,
              id: <NewsInd scrollToRef={refScrollUp} />,
            }}
          />
        </Route>
        <Route path="/:language/sample">
          <MultiLangPage
            pages={{
              en: <SampleBlogPost scrollToRef={refScrollUp} />,
              id: <SampleBlogPostInd scrollToRef={refScrollUp} />,
            }}
          />
        </Route>
        <Route path="/:language/contact">
          <MultiLangPage
            pages={{
              en: <Contact scrollToRef={refScrollUp} />,
              id: <ContactInd scrollToRef={refScrollUp} />,
            }}
          />
        </Route>
        <Route path="/*">
          <MultiLangPage
            pages={{
              en: <Home scrollToRef={refScrollUp} />,
              id: <HomeInd scrollToRef={refScrollUp} />,
            }}
          />
        </Route>
      </Switch>
    </Router>
  );
}
