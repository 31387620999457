import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  h1: {
    fontSize: 65,
    padding: 0,
    margin: 0,
    color: "#293EBB",
  },
  h2: {
    fontSize: 40,
    padding: 0,
    margin: 0,
    color: "#293EBB",
  },
  h3: {
    fontSize: 36,
    padding: 0,
    margin: 0,
    color: "#1B297C",
  },
  h4: {
    fontSize: 30,
    padding: 0,
    margin: 0,
    color: "#1B297C",
  },
});

export default function Heading({
  children,
  color,
  fontFamily = "Roboto",
  fontWeight = "bold",
  variant = "h1",
  style,
}) {
  const classes = useStyles();

  function getClassName(variant) {
    return { h1: classes.h1, h2: classes.h2, h3: classes.h3, h4: classes.h4 }[
      variant
    ];
  }

  const className = getClassName(variant);

  let component = <h1 />;
  if (variant == "h2") component = <h2 />;
  if (variant == "h3") component = <h3 />;
  if (variant == "h4") component = <h4 />;

  return (
    <component
      className={className}
      style={{ color, fontFamily, fontWeight, ...style }}
    >
      {children}
    </component>
  );
}
