import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Text from "../Text/Text";

const fontSize = 16;
const linesToShow = 7;
const lineHeight = 1.4;

const useStyles = makeStyles({
  root: {
    width: "20vw",
    maxWidth: 400,
    minWidth: 400,
    background: "#fff",
    margin: "1rem",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  imageBackground: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    transform: "scale(1.0)",
    transition: "opacity 0.4s, transform 0.4s",
  },
  hover: {
    transform: "scale(1.1)",
    opacity: 1,
  },
  text: {
    maxWidth: "400px",
    margin: "0 auto",
    lineHeight: 1.4,
    fontSize: fontSize,
  },
  collapsed: {
    display: "block" /* Fallback for non-webkit */,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height:
      fontSize * lineHeight * linesToShow - 1 /* Fallback for non-webkit */,
    WebkitLineClamp: linesToShow,
  },
});

function Milestone({ image, title, collapsable, content }) {
  const [hover, setHover] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const classes = useStyles();

  function handleClick() {
    if (collapsable) {
      setExpand(!expand);
    }
  }

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={clsx(classes.root)}
    >
      <div
        style={{
          width: "100%",
          height: 200,
          maxHeight: 200,
          minHeight: 200,
          borderRadius: 16,
          boxShadow:
            "0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text color="#FFFFFF" fontSize={56} fontWeight="bold">
            {title}
          </Text>
        </div>
        <img
          src={image}
          className={clsx(classes.imageBackground, { [classes.hover]: hover })}
          style={{
            opacity: 1.0,
          }}
        />
      </div>
      <div
        style={{
          paddingTop: 16,
          paddingLeft: 16,
          paddingRight: 16,
          cursor: collapsable ? "pointer" : "auto",
        }}
      >
        <p
          className={clsx(classes.text, {
            [classes.collapsed]: collapsable && !expand,
          })}
        >
          {content}
        </p>
      </div>
      {collapsable && (
        <div
          onClick={handleClick}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30.634"
            height="20.634"
            viewBox="0 0 30.634 20.634"
            style={{
              transform: expand ? "scale(0.5)" : "scale(0.5) rotate(180deg)",
            }}
          >
            <line
              id="Line_30"
              data-name="Line 30"
              y1="15"
              x2="12.5"
              transform="translate(2.817 2.817)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="4"
            />
            <line
              id="Line_36"
              data-name="Line 36"
              x1="12.5"
              y1="15"
              transform="translate(15.317 2.817)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="4"
            />
          </svg>
        </div>
      )}
    </div>
  );
}

export default Milestone;
