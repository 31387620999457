import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../components/Banner/Banner";
import banner from "../../images/Capabilities/Banners/banner.jpg";
import Content from "../../components/Content/Content";
import Heading from "../../components/Text/Heading";
import Footer from "../../components/Footer/Footer";
import Text from "../../components/Text/Text";
import Cascade from "../../components/Cascade/Cascade";
import Logo1 from "../../images/Logos/Capabilities/1Sun.svg";
import Logo2 from "../../images/Logos/Capabilities/2Fujitsu.svg";
import Logo3 from "../../images/Logos/Capabilities/3IBM.svg";
import Logo4 from "../../images/Logos/Capabilities/4HP.svg";
import Logo5 from "../../images/Logos/Capabilities/5VMWare.svg";
import Logo6 from "../../images/Logos/Capabilities/6F5.svg";
import Logo7 from "../../images/Logos/Capabilities/7Cisco.svg";
import Logo8 from "../../images/Logos/Capabilities/8Watchguard.svg";
import Logo9 from "../../images/Logos/Capabilities/9Thales.svg";
import Logo10 from "../../images/Logos/Capabilities/10Symantec.svg";
import Certificate1 from "../../images/Capabilities/Certifications/1.png";
import Certificate2 from "../../images/Capabilities/Certifications/2.png";
import Certification from "../../components/Certification/Certification";
import Service from "../../components/Service/Service";
import Card1 from "../../images/Capabilities/Cards/1.jpeg";
import Card2 from "../../images/Capabilities/Cards/2.jpg";
import Card3 from "../../images/Capabilities/Cards/3.jpeg";
import Card4 from "../../images/Capabilities/Cards/4.jpg";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

function Logo({ src, height = 100, width = "MAX(10vw, 200px)" }) {
  return (
    <img src={src} style={{ height, width, objectFit: "contain" }} alt="logo" />
  );
}

const icons = [
  <Logo src={Logo1} />,
  <Logo src={Logo2} />,
  <Logo src={Logo3} />,
  <Logo src={Logo4} />,
  <Logo src={Logo5} />,
  <Logo src={Logo6} />,
  <Logo src={Logo7} />,
  <Logo src={Logo8} />,
  <Logo src={Logo9} />,
  <Logo src={Logo10} />,
];

const services = [
  <Service
    title="Application Programming Interface Integration (API)"
    content="Before any integration begins, we provide the necessary technical specifications to accurately capture the needs of both our billers and collecting agents."
    image={Card1}
  />,
  <Service
    title="Established Standard Operational Procedure (SOP)"
    content="With over 20 years of industry knowledge and expertise, we have established a comprehensive SOP to fortify the foundation of our digital solutions and to guide our clients through the protean digital landscape. "
    image={Card2}
  />,
  <Service
    title="Transaction Reconciliation"
    content="We ensure that all transactions executed through our digital platform are fully accountable and traceable through our reconciliation process."
    image={Card3}
  />,
  <Service
    title="IT & Operations Helpdesk"
    content="Our team is available 24/7 to serve our clients and manage any technical or operational inquiries regarding API integration, network connection, transactions, etc."
    image={Card4}
  />,
];

export default function Capabilities({ scrollToRef }) {
  const classes = useStyles();

  return (
    <Content>
      <div ref={scrollToRef}>
        <Banner background={banner} height={500}>
          <Heading color="#FFFFFF">Capabilities</Heading>
        </Banner>
      </div>
      <section>
        <div className={classes.row}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "-webkit-fill-available",
                marginInline: 40,
                padding: "80px 40px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Heading variant="h3">Services</Heading>
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "-webkit-fill-available",
                    margin: 0,
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-around",
                    }}
                  >
                    {services.map((i) => (
                      <div
                        style={{
                          width: "18vw",
                          maxWidth: 350,
                          minWidth: 300,
                          display: "flex",
                          justifyContent: "center",
                          margin: "40px 0px",
                        }}
                      >
                        {i}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className={classes.row}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "-webkit-fill-available",
                marginInline: 40,
                padding: "80px 40px",
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid #707070",
              }}
            >
              <Heading variant="h3">Systems and Infrastructure</Heading>
              <Text fontSize={18}>
                We invest in trusted and cutting-edge technology to ensure that
                our digital payment infrastructure is buttressed by both high
                calibre software and hardware. We implement, proven, scalable
                and secure solutions and systems to assure our clients that each
                transaction request is acknowledged within a millisecond. We
                pride ourselves in achieving consistent system availability and
                high calibre performance to safeguard our clients against
                systemic disruptions.
              </Text>
              <Cascade
                items={icons.map((i) => (
                  <div
                    style={{
                      minWidth: "20vw",
                      display: "flex",
                      justifyContent: "center",
                      margin: "40px 20px",
                    }}
                  >
                    {i}
                  </div>
                ))}
                margin={0}
                padding={0}
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className={classes.row}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "-webkit-fill-available",
                marginInline: 40,
                padding: "80px 40px 20px 40px",
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid #707070",
              }}
            >
              <Heading variant="h3">Certifications</Heading>
              <Text fontSize={18}>
                We aim to be the trusted partner of your choice and we uphold
                this commitment by implementing measures and systems that meet
                international standards for information security and quality
                management.
              </Text>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            minHeight: 500,
            flexWrap: "wrap",
          }}
        >
          <Certification
            src={Certificate1}
            title="ISO/IEC 27001:2013"
            body="ISO 27001: 2013 is the international standard for information security
          management. SYB has met the international criterion to establish,
          operate, review and continually improve upon our information security
          management system."
          />
          <Certification
            src={Certificate2}
            title="ISO/IEC 9001:2015"
            body="ISO 9001 is the international standard for quality management systems. This certification demonstrates SYB’s ardent dedication to ensure consistent quality across the provision of our products and services."
            dark
          />
        </div>
      </section>
      <Footer language="en" />
    </Content>
  );
}
