import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../components/Banner/Banner";
import banner from "../../images/OurJourney/Banners/banner.png";
import tile1 from "../../images/OurJourney/Tiles/1.jpg";
import tile2 from "../../images/OurJourney/Tiles/2.jpg";
import tile3 from "../../images/OurJourney/Tiles/3.jpeg";
import tile4 from "../../images/OurJourney/Tiles/4.jpg";
import tile5 from "../../images/OurJourney/Tiles/5.jpg";
import tile6 from "../../images/OurJourney/Tiles/6.jpeg";
import tile7 from "../../images/OurJourney/Tiles/7.jpg";
import tile8 from "../../images/OurJourney/Tiles/8.jpg";
import tile9 from "../../images/OurJourney/Tiles/9.jpg";
import tile10 from "../../images/OurJourney/Tiles/10.jpg";
import tile11 from "../../images/OurJourney/Tiles/11.jpg";
import tile12 from "../../images/OurJourney/Tiles/12.jpg";
import tile13 from "../../images/OurJourney/Tiles/13.jpg";
import tile14 from "../../images/OurJourney/Tiles/14.jpg";
import tile15 from "../../images/OurJourney/Tiles/15.jpg";
import tile16 from "../../images/OurJourney/Tiles/16.jpeg";
import tile17 from "../../images/OurJourney/Tiles/17.jpeg";
import tile18 from "../../images/OurJourney/Tiles/18.jpeg";
import tile19 from "../../images/OurJourney/Tiles/19.jpeg";
import tile20 from "../../images/OurJourney/Tiles/20.jpeg";
import tile21 from "../../images/OurJourney/Tiles/21.jpeg";
import Content from "../../components/Content/Content";
import Heading from "../../components/Text/Heading";
import Footer from "../../components/Footer/Footer";
import Text from "../../components/Text/Text";
import Cascade from "../../components/Cascade/Cascade";
import Milestone from "../../components/Milestone/Milestone";
import { Link, useParams } from "react-router-dom";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
  highlight: {
    fontWeight: "bold",
    color: "#F0A202",
  },
});

const mileStones = [
  <Milestone
    title="2000"
    image={tile1}
    content="Kami meluncurkan platform pembayaran digital pertama kami, PRAQTIS, dengan PT PLN (Persero) dan 3 bank, PT Bank Rakyat Indonesia (Persero) Tbk (BRI), PT Bank Lippo Tbk (sekarang bergabung dengan PT Bank CIMB Niaga Tbk) dan PT Bank Ekonomi Raharja Tbk (sekarang bergabung dengan PT Bank HSBC Indonesia)."
  />,
  <Milestone
    title="2001"
    image={tile2}
    content="Kami memperluas jaringan perbankan kami ke 8 bank, termasuk PT Bank Central Asia Tbk (BCA), bank swasta terbesar di Indonesia."
  />,
  <Milestone
    title="2002"
    image={tile3}
    content="Peluncuran kembali layanan pembayaran PLN dengan penerbitan satu nomor pelanggan unik yang berlaku secara nasional. Kami juga memperluas jaringan bank menjadi 15 bank."
  />,
  <Milestone
    title="2003"
    image={tile4}
    content="Melakukan uji coba platform pembayaran digital pertama kami dengan agen-agen dan lembaga non-perbankan. Bersamaan dengan itu, kami meluncurkan aplikasi pembayaran tagihan digital perdana kami, Online Payment Point (OPP), untuk agen-agen yang direkrut untuk melayani pelanggan yang tidak memiliki rekening bank (“unbanked”) di seluruh Indonesia."
  />,
  <Milestone
    title="2004"
    collapsable
    image={tile5}
    content="Salah satu bank pemerintah terbesar di Indonesia, PT Bank Mandiri (Persero) Tbk, bergabung dengan ekosistem pembayaran digital kami untuk menerima layanan pembayaran PLN. Kami juga memperluas kategori pembayaran tagihan kami ke layanan leasing dengan tiga perusahaan leasing sepeda motor terbesar di Indonesia, yaitu, PT Federal International Finance (FIF) di bawah PT Astra International Tbk, PT Bussan Auto Finance (BAF) di bawah Grup Mitsui, serta PT Summit Oto Finance (SOF) dan PT Oto Multiartha (OTO) di bawah Sumitomo Group."
  />,
  <Milestone
    title="2005"
    image={tile6}
    content="PT Bank Maybank Indonesia Tbk, PT Bank Mega Tbk, PT Bank Ganesha Tbk dan PT Bank Ina Perdana Tbk, bergabung dengan ekosistem pembayaran digital kami untuk menerima layanan pembayaran PLN."
  />,
  <Milestone
    title="2006"
    image={tile7}
    content="Standard Chartered Bank Indonesia dan PT Bank HSBC Indonesia bergabung dengan ekosistem pembayaran digital kami untuk menerima layanan pembayaran PLN."
  />,
  <Milestone
    title="2007"
    image={tile8}
    content="PT Bank Sinarmas Tbk bergabung dengan ekosistem pembayaran digital kami untuk menerima layanan pembayaran PLN."
  />,
  <Milestone
    title="2008"
    image={tile9}
    content="Kami meluncurkan lini produk electronic data capture (EDC) pertama kami untuk PT Federal International Finance (FIF) di bawah PT Astra International Tbk. Kami juga meluncurkan layanan pembayaran biaya pengelolaan real estate perdana kami untuk PT Tata Mandiri Daerah Lippo Karawaci."
  />,
  <Milestone
    title="2009"
    image={tile10}
    content="Kami meluncurkan layanan pembayaran tagihan telekomunikasi pertama kami dengan PT Finnet Indonesia. Di tahun ini BRI Link bergabung dengan ekosistem pembayaran digital kami untuk menerima layanan pembayaran multibiller."
  />,
  <Milestone
    title="2010"
    image={tile11}
    content="Penyedia layanan pos milik pemerintah Indonesia, PT Pos Indonesia (Persero), bergabung dengan ekosistem pembayaran digital kami untuk menerima layanan pembayaran multibiller."
  />,
  <Milestone
    title="2011"
    image={tile12}
    content="Kami meluncurkan layanan remitensi pertama kami dengan salah satu perusahaan remitensi internasional terbesar, PT Western Union Indonesia. Kami memperkenalkan platform tagihan baru, PaymentKita, untuk layanan pembayaran multibiller kami di PT Bank Mandiri (Persero) Tbk."
  />,
  <Milestone
    title="2012"
    image={tile13}
    content="Kami meluncurkan layanan pembayaran tiket kereta api pertama kami untuk PT Kereta Api Indonesia (Persero)."
  />,
  <Milestone
    title="2013"
    image={tile14}
    content="Kami meluncurkan layanan pembayaran tiket pesawat pertama kami untuk PT Citilink Indonesia."
  />,
  <Milestone
    title="2014"
    collapsable
    image={tile15}
    content="Kami mengembangkan layanan “self service terminals” di luar layanan mesin “Electronic Data Capture”(EDC), seperti anjungan tunai mandiri (ATM), mesin setoran tunai (CDM), dan mesin daur ulang tunai (CRM). Kami memperkenalkan layanan pembayaran pajak pertama kami di Direktorat Jenderal Pajak (DJP), dan layanan pembayaran televisi prabayar pertama kami untuk PT Digital Vision Nusantara (KVision)."
  />,
  <Milestone
    title="2015"
    image={tile16}
    content="Bersama PT Bank Mandiri (Persero) Tbk, kami meluncurkan layanan pembayaran premi asuransi   kesehatan nasional Indonesia, BPJS Kesehatan. Kami juga meluncurkan layanan pembayaran tagihan media pertama untuk PT Kompas Media Nusantara (Kompas) dan PT MNC Kabel Mediacom (MNC Play)."
  />,
  <Milestone
    title="2016"
    image={tile17}
    content="Platform e-commerce terbesar di Indonesia, PT Tokopedia (Tokopedia), bergabung dengan ekosistem pembayaran digital kami untuk menerima layanan pembayaran multibiller."
  />,
  <Milestone
    title="2017"
    collapsable
    image={tile18}
    content="Kami memperluas layanan pembayaran premi BPJS Kesehatan ke PT Bank Central Asia Tbk (BCA), PT Bank Permata Tbk, PT Bank CIMB Niaga Tbk dan PT Bank Panin Tbk. Kami merambah ke bidang pendidikan dan menerapkan layanan pembayaran biaya kuliah untuk Universitas Muhammadiyah Malang (UMM), Universitas Padjajaran (UNPAD) dan Institut Teknologi Bandung (ITB). Kami meluncurkan layanan pembayaran pajak pertama kami dengan Pajak Bumi dan Bangunan (PBB) Depok."
  />,
  <Milestone
    title="2018"
    collapsable
    image={tile19}
    content="Kami meluncurkan layanan isi ulang uang elektronik pertama kami, E-Money Mandiri, bersama PT Pos Indonesia (Persero). Kami juga meluncurkan layanan pembayaran tagihan internet pertama kami dengan PT Telkom Satelit Indonesia dan PT Sampoerna Telekomunikasi Indonesia. Selama tahun ini, perusahaan pegadaian terbesar di Indonesia milik negara, PT Pegadaian (Persero), bergabung dengan ekosistem pembayaran digital kami untuk menerima layanan pembayaran multibiller."
  />,
  <Milestone
    title="2019"
    collapsable
    image={tile20}
    content="Kami meluncurkan layanan isi ulang e-Wallet pertama kami dengan PT Dompet Anak Bangsa (GoPay), PT Visionet Internasional (OVO) dan PT Espay Debit Indonesia Koe (DANA). Kami juga meluncurkan layanan voucher Google Play pertama kami dengan PT Bank Central Asia Tbk (BCA). Kami memperluas ekosistem pembayaran digital kami untuk mencakup e-commerce dan e-wallet dengan PT Shopee International Indonesia (Shopee), PT Bukalapak.com (Bukalapak), PT Witami Tunai Mandiri (Truemoney) dan PT Dompet Harapan Bangsa (OY!)."
  />,
  <Milestone
    title="2020"
    image={tile21}
    content="Di tengah pandemi Covid-19, SYB diberikan penghargaan oleh BPJS Kesehatan karena memproses volume autodebit terbesar untuk pembayaran premi asuransi kesehatan. Di tahun ini PT Fintek Karya Nusantara (LinkAja), bergabung dengan ekosistem pembayaran digital kami untuk menerima layanan pembayaran multibiller."
  />,
];

export default function ClientNetwork({ scrollToRef }) {
  const classes = useStyles();
  const { language } = useParams();

  return (
    <Content>
      <div ref={scrollToRef}>
        <Banner background={banner} height={500}>
          <Heading color="#FFFFFF">Awal Mula</Heading>
        </Banner>
      </div>
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: 100,
              marginBottom: 0,
              width: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div className={classes.textSectionWrapper}>
              <div className={classes.textSectionBorder} />
              <div className={classes.textSection}>
                <Heading>Awal Mula</Heading>
                <Text>
                  Perjalanan kami dimulai di tahun 2000 saat SYB meluncurkan
                  platform jasa pembayaran digital pertama di Indonesia,{" "}
                  <span className={classes.highlight}>‘PRAQTIS’</span>, dengan
                  PT Perusahaan Listrik Negara (PLN) (Persero), perusahaan
                  distribusi listrik tunggal pemerintah. Sebagai arsitek dari
                  produk pembayaran digital nasional tanpa batas, SYB menggiring
                  perubahan paradigma, yang berperan sebagai pendorong untuk
                  industri pembayaran digital di Indonesia.
                </Text>
                <Text>
                  Perjalanan kami dimulai di tahun 2000 saat SYB meluncurkan
                  platform jasa pembayaran digital pertama di Indonesia,{" "}
                  <span className={classes.highlight}>‘PRAQTIS’</span>, dengan
                  PT Perusahaan Listrik Negara (PLN) (Persero), perusahaan
                  distribusi listrik tunggal pemerintah. Sebagai arsitek dari
                  produk pembayaran digital nasional tanpa batas yang pertama,
                  SYB menggiring perubahan paradigma, yang berperan sebagai
                  pendorong untuk industri pembayaran digital di Indonesia.
                </Text>
                <Text>
                  Perjalanan kami mencerminkan janji yang konsisten untuk
                  menerapkan{" "}
                  <Link
                    to={`/${language}/visionvalues`}
                    style={{ textDecoration: "none" }}
                  >
                    <span className={classes.highlight}>ilmu</span>
                  </Link>
                  {" dan "}
                  <Link
                    to={`/${language}/visionvalues`}
                    style={{ textDecoration: "none" }}
                  >
                    <span className={classes.highlight}>inovasi</span>
                  </Link>{" "}
                  terdepan dan melaksanakan segala tugas dengan{" "}
                  <Link
                    to={`/${language}/visionvalues`}
                    style={{ textDecoration: "none" }}
                  >
                    <span className={classes.highlight}>integritas</span>
                  </Link>
                  , dimana kami senantiasa berusaha untuk memberikan pelanggan
                  kami dengan peluang yang tak tertandingi dalam ekonomi digital
                  yang inklusif. Sejalan dengan kemajuan teknologi, kami terus
                  menghadirkan solusi pembayaran digital yang menarik dan
                  kreatif untuk mendorong efisiensi finansial di seluruh Asia.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Cascade items={mileStones} />
      </section>
      <Footer language="id" />
    </Content>
  );
}
