import React from "react";
import Text from "../Text/Text";
import { Slide, Fade } from "react-awesome-reveal";

export default function LogoCascade({ noBorder, header, logos }) {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          borderTop: noBorder ? "none" : "1px solid #707070",
          width: "-webkit-fill-available",
          margin: 40,
          padding: 40,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Text
          color="#F3A83F"
          textTransform="uppercase"
          fontWeight="bold"
          style={{ zIndex: 9999 }}
        >
          {header}
        </Text>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Fade triggerOnce={true} duration={2000}>
            {logos?.map((x) => (
              <Slide direction="down" triggerOnce={true} duration={2000}>
                {x}
              </Slide>
            ))}
          </Fade>
        </div>
      </div>
    </div>
  );
}
