import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
    flexGrow: 1,
    overflow: "scroll",
  },
});

export default function Content({ children, backgroundColor }) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ backgroundColor }}>
      {children}
    </div>
  );
}
