import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  image: {
    width: "100%",
    height: "100%",
    transform: "scale(1.0)",
    transition: "opacity 0.4s, transform 0.4s",
  },
  hover: {
    transform: "scale(1.1)",
    opacity: 1,
  },
});

export default function Tile({ children, background, fadeIn }) {
  const [hover, setHover] = React.useState(false);
  const classes = useStyles();

  return (
    <div
      style={{
        overflow: "hidden",
        width: "100%",
        height: "100%",
        position: "relative",
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </div>
      <img
        src={background}
        className={clsx(classes.image, { [classes.hover]: hover })}
        style={{
          opacity: fadeIn ? 0.7 : 1.0,
        }}
      />
    </div>
  );
}
