import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../components/Banner/Banner";
import banner from "../../images/News/Banners/banner.jpeg";
import tile1 from "../../images/News/Tiles/1.jpeg";
import tile2 from "../../images/News/Tiles/2.jpeg";
import tile3 from "../../images/News/Tiles/3.jpeg";
import tile4 from "../../images/News/Tiles/4.jpeg";
import Content from "../../components/Content/Content";
import Heading from "../../components/Text/Heading";
import Footer from "../../components/Footer/Footer";
import Text from "../../components/Text/Text";
import BlogPost from "../../components/BlogPost/BlogPost";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function News({ scrollToRef }) {
  const classes = useStyles();

  return (
    <Content>
      <div ref={scrollToRef}>
        <Banner background={banner} height={500}>
          <Heading color="#FFFFFF">Ruang Pres</Heading>
        </Banner>
      </div>
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: 100,
              width: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div className={classes.textSectionWrapper}>
              <div className={classes.textSectionBorder} />
              <div className={classes.textSection}>
                <Text>
                  Pengumuman terbaru, wawasan industri dan perspektif dari SYB.
                  Pelajari isu dan inovasi terbaru yang mendorong masa depan
                  industri pembayaran digital.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className={classes.row}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "-webkit-fill-available",
                marginInline: 40,
                padding: "0px 40px 80px 40px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Heading variant="h3" style={{ margin: "1rem" }}>
                Publikasi
              </Heading>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <BlogPost
                  image={tile1}
                  date="15 April 2021"
                  title="Perkembangan Fintech di Indonesia"
                />
                <BlogPost
                  image={tile2}
                  date="15 April 2021"
                  title="Perkembangan Fintech di Indonesia"
                />
                <BlogPost
                  image={tile3}
                  date="15 April 2021"
                  title="Perkembangan Fintech di Indonesia"
                />
                <BlogPost
                  image={tile4}
                  date="15 April 2021"
                  title="Perkembangan Fintech di Indonesia"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer language="id" />
    </Content>
  );
}
