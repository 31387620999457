import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../components/Banner/Banner";
import banner from "../../images/VisionValues/Banners/banner.jpeg";
import card1 from "../../images/VisionValues/Cards/1.jpg";
import card2 from "../../images/VisionValues/Cards/2.jpg";
import card3 from "../../images/VisionValues/Cards/3.jpg";
import blogtile from "../../images/VisionValues/Tiles/blogtile.jpg";
import Content from "../../components/Content/Content";
import Heading from "../../components/Text/Heading";
import Footer from "../../components/Footer/Footer";
import Card from "../../components/Card/Card";
import Poster from "../../components/Poster/Poster";
import Text from "../../components/Text/Text";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function VisionValues({ scrollToRef }) {
  const classes = useStyles();

  return (
    <Content>
      <div ref={scrollToRef}>
        <Banner background={banner} height={500}>
          <Heading color="#FFFFFF">Visi dan Nilai-Nilai</Heading>
        </Banner>
      </div>
      <section>
        <div className={classes.row}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "-webkit-fill-available",
                marginInline: 40,
                padding: "80px 40px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <Poster image={blogtile}>
                    <Text
                      color="#FFFFFF"
                      fontSize={32}
                      style={{ margin: 0, padding: "10%" }}
                    >
                      Kami berusaha keras untuk{" "}
                      <span style={{ fontWeight: "bold", color: "#F0A202" }}>
                        memperkaya kehidupan
                      </span>{" "}
                      dengan memberikan produk dan jasa keuangan yang inovatif
                      dan dapat diakses bersamaan oleh institusi, bisnis dan
                      individu.
                    </Text>
                  </Poster>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <Card image={card1}>
                    <Text style={{ padding: 0, margin: 0, fontSize: 15 }}>
                      Kami memadukan semangat kami untuk melayani ekonomi
                      digital inklusif dengan
                      <span style={{ fontWeight: "bold", color: "#F0A202" }}>
                        {" "}
                        ilmu
                      </span>{" "}
                      terdepan yang diterapkan untuk merumuskan solusi keuangan
                      yang kreatif dan handal melalui platform digital kami.
                    </Text>
                  </Card>
                  <Card image={card2}>
                    <Text style={{ padding: 0, margin: 0, fontSize: 15 }}>
                      <span style={{ fontWeight: "bold", color: "#F0A202" }}>
                        Inovasi
                      </span>{" "}
                      erus menerus menjadi inti dari visi kami. Kami senantiasa
                      berusaha merekayasa dan memberikan teknologi terdepan
                      untuk pengalaman pengguna yang dinamik dan mulus.
                    </Text>
                  </Card>
                  <Card image={card3}>
                    <Text style={{ padding: 0, margin: 0, fontSize: 15 }}>
                      Pekerjaan kami didorong oleh komitmen kuat untuk bersikap
                      dengan
                      <span style={{ fontWeight: "bold", color: "#F0A202" }}>
                        {" "}
                        integritas
                      </span>{" "}
                      dalam cara kami menciptakan nilai-nilai untuk pelanggan.
                      Etika dan prinsip perusahaan kami adalah untuk bersikap
                      tegas ditengah lanskap era digital yang terus berubah.
                    </Text>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer language="id" />
    </Content>
  );
}
