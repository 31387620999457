import * as React from "react";
import Text from "../Text/Text";

function TileComponent() {
  return (
    <div
      style={{
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          fill: "#fff",
          fontSize: 100,
          fontFamily: "Montserrat-Bold, Montserrat",
          fontWeight: 700,
          padding: 0,
          margin: 0,
          textDecoration: "none",
        }}
      >
        10K+
      </Text>
      <Text
        style={{
          fill: "#fff",
          fontSize: 36,
          fontFamily: "Montserrat-Bold, Montserrat",
          fontWeight: 700,
          padding: 0,
          margin: 0,
          textDecoration: "none",
        }}
      >
        AGENTS
      </Text>
    </div>
  );
}

export default TileComponent;
