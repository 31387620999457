import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../../components/Banner/Banner";
import banner from "../../../images/SampleBlogPost/Banners/banner.jpeg";
import Content from "../../../components/Content/Content";
import Heading from "../../../components/Text/Heading";
import Footer from "../../../components/Footer/Footer";
import Text from "../../../components/Text/Text";

const useStyles = makeStyles({
  mainSection: {
    backgroundColor: "#FFFFFF",
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textSectionWrapper: {
    display: "flex",
  },
  textSection: {
    padding: "0px 40px",
  },
  textSectionBorder: {
    borderLeft: "2px solid #5669DA",
    margin: "20px 0px",
  },
});

export default function SampleBlogPost({ scrollToRef }) {
  const classes = useStyles();

  return (
    <Content>
      <div ref={scrollToRef}>
        <Banner background={banner} height={500}>
          <Heading color="#FFFFFF">Pressroom</Heading>
          <Heading
            variant="h2"
            color="#FFFFFF"
            fontWeight={100}
            style={{ lineHeight: 1.5 }}
          >
            The latest announcements, industry insights, and perspectives from
            SYB
          </Heading>
        </Banner>
      </div>
      <section id="mainSection" className={classes.mainSection}>
        <div className={classes.row}>
          <div
            style={{
              margin: 100,
              width: "70%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Heading
              variant="h2"
              color="#1B297C"
              style={{ marginBottom: "1rem" }}
            >
              Development of Fintech in Indonesia
            </Heading>
            <Heading variant="h4" style={{ marginBottom: "1rem" }}>
              23 May 2021
            </Heading>
            <div className={classes.textSectionWrapper}>
              <div className={classes.textSectionBorder} />
              <div className={classes.textSection}>
                <Text color="#F0A202">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.
                </Text>
              </div>
            </div>
            <Text>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </Text>
            <Text>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </Text>
            <Text>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur .
            </Text>
          </div>
        </div>
      </section>
      <Footer language="en" />
    </Content>
  );
}
